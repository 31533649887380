<template>
  <div class="px-1 mt-3">
    <v-card flat rounded>
      <v-row dense>
        <v-col cols="12">
          <div class="ul-icon-box mx-auto mb-3 mt-10">
            <div class="bg-group ul-animate fallingClouds" />
            <v-img
              height="100"
              width="100"
              class="mx-auto"
              src="@/assets/images/svg/contact.svg"
            />
          </div>
          <div class="text-center">
            <h4 class="font-weight-bold">Contact us!</h4>
            <p class="text--lighten-1">Send your questions to our experts</p>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="6" lg="5" xl="4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="mt-2 mx-4"
          >
            <!--<v-list-item-content class="text-left" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                CATEGORY <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-select
              v-model="category"
              :items="contactCategory"
              label="Category"
              :rules="[v => !!v || 'This field is required']"
              outlined
              dense
            />-->
            <v-list-item-content class="text-left" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                PENTEST <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
            </v-list-item-content>
            <v-select
              v-model="pentest"
              :items="itemsPentest"
              label="Pentest"
              outlined
              dense
              item-text="title"
              item-value="id"
              @click="getPentestData()"
            />
            <v-list-item-content class="text-left" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                SUBJECT <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-text-field
              v-model="subject"
              label="Subject"
              :rules="[v => !!v || 'This field is required']"
              outlined
              dense
            />
            <v-list-item-content class="text-left" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                MESSAGE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-textarea
              v-model="message"
              label="Message"
              :rules="[v => !!v || 'This field is required']"
              counter
              outlined
              dense
              no-resize
            />
            <div class="text-center">
              <v-btn
                depressed
                color="success"
                class="mt-2 mb-10 mx-auto"
                large
                block
                min-width="180"
                @click="submit"
              >
                <v-icon class="pr-2">mdi-email-fast-outline</v-icon>
                Send
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { contactCategory } from '@/data/common.js'
import UserService from '@/services/user.service.js'
import PentestService from '@/services/pentest.service'
import snackbarPlugin from '@/plugins/snackbar'

export default {
  name: 'ContactUs',
  metaInfo: {
    title: 'Contact us'
  },
  data: () => ({
    contactCategory,
    itemsPentest: null,
    pentest: null,
    category: null,
    subject: null,
    message: null,
    valid: true
  }),
  mounted() {
    this.$store.dispatch('changeThemeLoadingState', false)
  },
  methods: {
    async getPentestData() {
      const res = await PentestService.getPentests(1, 100)
      this.itemsPentest = res.results
    },
    submit() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Sending ...')
        const res = UserService.getLocalProfile()
        UserService.contactUs({
          fullName: res.fullName,
          email: res.email,
          phone: res.phone,
          category: 'Service requests',
          subject: this.subject + ' | PENTEST -> ' + this.pentest,
          message: this.message
        }).then(() => {
          snackbarPlugin.showSuccess('Your message has been sent !')
        })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
